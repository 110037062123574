<template>
    <div class="page-asap-workflow-summary">
        <app-table-details
            :rows="rows"
            :data="summary"
        />
    </div>
</template>

<script>
import appTableDetails from '@/components/app-table-details'

import formatDate from '@/helpers/format-date'

export default {
    props: {
        response: { type: Object,  default: () => {} },
    },

    components: {
        appTableDetails,
    },

    data() {
        return {
            rows: [
                { key: 'workflow_name',  title: 'Workflow name'               },
                { key: 'started_at',     title: 'Started at'                  },
                { key: 'closed_time',    title: 'Closed time'                 },
                { key: 'workflow_id',    title: 'Workflow ID'                 },
                { key: 'run_id',         title: 'Run ID'                      },
                { key: 'input',          title: 'Input',         type: 'code' },
                { key: 'result',         title: 'Result',        type: 'code' },
                { key: 'task_queue',     title: 'Task QUEUE'                  },
                { key: 'history_events', title: 'History events'              },
            ],
        }
    },

    computed: {
        summary() {
            const workflow_name = this.response
                ? this.response.history.events[0].Attributes.workflow_execution_started_event_attributes.workflow_type.name
                : '?'

            const started_at = this.response
                ? formatDate(new Date(this.response.history.events[0].event_time), 'YYYY-MM-DD HH:mm:ss')
                : '?'

            const closed_time = this.response
                ? formatDate(new Date(this.response.history.events[this.response.history.events.length - 1].event_time), 'YYYY-MM-DD HH:mm:ss')
                : '?'

            const workflow_id = this.$route.params.workflow_id

            const run_id = this.$route.params.run_id

            const input = {
                code: '?',
                title: 'Input',
            }

            const result = {
                code: '?',
                title: 'Result',
            }

            if (this.response) {
                const len = this.response.history.events.length

                for (let i = 0; i < len; i++) {
                    if (this.response.history.events[i].Attributes.workflow_execution_started_event_attributes) {
                        input.code = atob(this.response.history.events[i].Attributes.workflow_execution_started_event_attributes.input.payloads[0].data)
                    }
                }

                for (let i = len - 1; i >= 0; i--) {
                    if (this.response.history.events[i].Attributes.workflow_execution_completed_event_attributes) {
                        result.code = atob(this.response.history.events[i].Attributes.workflow_execution_completed_event_attributes.result.payloads[0].data)
                    }
                }
            }

            const task_queue = this.response
                ? this.response.history.events[0].Attributes.workflow_execution_started_event_attributes.task_queue.name
                : '?'

            const history_events = this.response
                ? this.response.history.events.length
                : '?'

            return {
                workflow_name,
                started_at,
                closed_time,
                workflow_id,
                run_id,
                input,
                result,
                task_queue,
                history_events,
            }
        },
    },
}
</script>

<style lang="scss">
</style>